import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'
import { Link } from "gatsby"
import CareersForm from "@Components/forms/career";
const ApplyModal = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <a onClick={() => handleShow()}  className="btn">
                            <i className="icon-cross icon-cross-job"> </i>
                            quick apply
                        </a>
                        <Modal show={show} 
            size="lg"
            backdrop="static"
            onHide={handleClose}
            >
        <Modal.Header closeButton>
          <Modal.Title> Apply this "{props.posting_title}" job</Modal.Title>
        </Modal.Header>
        <Modal.Body><CareersForm job_url={props.job_url}/></Modal.Body>
        
      </Modal>
        </>
    );
}
export default ApplyModal;